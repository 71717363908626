{
  "name": "@dotlottie/dotlottie-js",
  "version": "1.3.0",
  "type": "module",
  "description": "This library helps in creating and modifying .lottie files.",
  "repository": {
    "type": "git",
    "url": "git+https://github.com/dotlottie/dotlottie-js.git",
    "directory": "packages/dotlottie-js"
  },
  "homepage": "https://github.com/dotlottie/dotlottie-js#readme",
  "bugs": "https://github.com/dotlottie/dotlottie-js/issues",
  "author": "dotLottie",
  "contributors": [
    "Karam Ali <karam@lottiefiles.com>",
    "Sam Osborne <sam@lottiefiles.com>",
    "Jawish Hameed <jawish@lottiefiles.com>",
    "Abdelrahman Ashraf <a.theashraf@gmail.com>"
  ],
  "license": "MIT",
  "engines": {
    "node": ">=18.0.0"
  },
  "main": "./dist/index.node.js",
  "exports": {
    ".": {
      "node": "./dist/index.node.js",
      "default": "./dist/index.browser.js"
    },
    "./*": "./dist/*.js"
  },
  "browser": "dist/index.browser.js",
  "types": "./dist/index.browser.d.ts",
  "typesVersions": {
    "*": {
      "*": [
        "dist/*",
        "dist/index.browser.d.ts"
      ]
    }
  },
  "files": [
    "dist"
  ],
  "scripts": {
    "build": "tsup",
    "dev": "tsup --watch",
    "docs": "typedoc src",
    "lint": "eslint --fix .",
    "stats:eslint": "cross-env TIMING=1 eslint .",
    "stats:ts": "tsc -p tsconfig.build.json --extendedDiagnostics",
    "test": "pnpm run test:node && pnpm run test:browser",
    "test:browser": "vitest --config=vitest.browser.config.js",
    "test:node": "vitest --config=vitest.config.js",
    "type-check": "tsc --noEmit"
  },
  "dependencies": {
    "@lottie-animation-community/lottie-types": "^1.2.0",
    "browser-image-hash": "^0.0.5",
    "fflate": "^0.8.1",
    "file-type": "^19.6.0",
    "sharp": "^0.33.2",
    "sharp-phash": "^2.1.0",
    "valibot": "^0.13.1"
  },
  "devDependencies": {
    "@types/jasmine": "4.3.5",
    "@types/node": "22.8.7",
    "@types/sharp": "0.31.1",
    "@vitest/browser": "2.1.3",
    "@vitest/coverage-v8": "2.1.3",
    "cross-env": "7.0.3",
    "js-base64": "3.7.5",
    "nodemon": "2.0.20",
    "playwright": "^1.48.2",
    "tsup": "8.3.0",
    "typescript": "4.7.4",
    "vite-plugin-arraybuffer": "^0.0.8",
    "vitest": "^2.1.3"
  },
  "publishConfig": {
    "access": "public"
  }
}
